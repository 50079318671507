import axios from 'axios';

export const fetchData = async () => {
  try {
    let data = {};
    const response = await axios.get('https://api.railtrack.yunethw.me/v1/trains');
    data.payload = response.data;
    data.status = response.status;
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export const fetchTrainData = async (trainId) => {
  try {
    let data = {};
    const response = await axios.get(`https://api.railtrack.yunethw.me/v1/trains/${trainId}`);
    data.payload = response.data;
    data.status = response.status;
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}