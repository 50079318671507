import './style.css';
import {Feature, Map, Overlay, View} from 'ol';
import {Zoom, defaults} from 'ol/control';
import TileLayer from 'ol/layer/Tile';
import VectorSource from "ol/source/Vector";
import Vector from 'ol/layer/Vector';
import {Stroke, Style} from "ol/style";
import GeoJSON from 'ol/format/GeoJSON';
import OSM from 'ol/source/OSM';
import { Popover } from 'bootstrap';
import {fromLonLat} from 'ol/proj';
import { Point } from 'ol/geom';
import { Select } from 'ol/interaction';
import { click, pointerMove } from 'ol/events/condition';
import { fetchData, fetchTrainData } from "./api";
import {isNull} from "ol/format/filter";

const map = new Map({
	target: 'map',
	controls: defaults().extend([
		new Zoom({className: 'zoom-control'}),
	]),
	layers: [
		new TileLayer({
			source: new OSM()
		})
	],
	view: new View({
		center: fromLonLat([80.615, 7.904]),
		zoom: 8,
	})
});

const styles = {
	'LineString': new Style({
		stroke: new Stroke({
			color: 'rgba(43,159,213,0.9)',
			width: 3,
		}),
	}),
	'Point': {
		'circle-radius': 6,
		'circle-fill-color': 'rgba(55,255,0,0.9)',
		'circle-stroke-color': 'rgba(30,124,10,0.9)',
		"circle-stroke-width": 1,
	}
};

// geo json railway lines
const geoJsonLayerSource = new VectorSource({
	url: 'https://api.maptiler.com/data/11e75156-89d6-4033-a4e1-0bc38ebbc6a7/features.json?key=tl3uMmgCM4DOMxMKA6Hx',
	format: new GeoJSON()
});
const geoJsonLayer = new Vector({
	source: geoJsonLayerSource,
	style: (feature) => { return styles[feature.getGeometry().getType() ] }
});

// train points
const points = {}
const pointVectors = new VectorSource();
const pointLayer = new Vector({
	source: pointVectors,
	style: styles['Point']
});

// popover
const popupElement = document.getElementById('popup');
const popup = new Overlay({ element: popupElement });

// train card
const cardElement = document.getElementById('card-container');
const trainTitleElement = document.getElementById('train-title');
const fromStationElement = document.getElementById('from-station');
const fromTimeElement = document.getElementById('from-time');
const toStationElement = document.getElementById('to-station');
const toTimeElement = document.getElementById('to-time');
const trainTypeElement = document.getElementById('train-type');
const trainDurationElement = document.getElementById('train-duration');
const trainDistanceElement = document.getElementById('train-distance');
const trainPowerunitElement = document.getElementById('train-powerunit');
const trainSpeedElement = document.getElementById('train-speed');
const trainLocationElement = document.getElementById('train-location');
const trainElevationElement = document.getElementById('train-elevation');
const trainLastUpdateElement = document.getElementById('train-last-update');

// select interactions
const selectClick = new Select({ condition: click });
const selectPointerMove = new Select({ condition: pointerMove });

map.addLayer(geoJsonLayer);
map.addLayer(pointLayer);
map.addOverlay(popup);
map.addInteraction(selectClick);
map.addInteraction(selectPointerMove);
fetchAndLoadData();

selectClick.on('select', async (e) => {
    if (e.selected.length !== 0 && e.selected[0]?.getProperties().geometry instanceof Point) {
        // console.log(e.selected[0].getProperties().geometry.values_);
		let trainValues = e.selected[0].getProperties().geometry.values_;
		trainTitleElement.innerText = `${trainValues.trainNumber} ${trainValues.trainName}`;
		fromStationElement.innerText = trainValues.fromStation;
		toStationElement.innerText = trainValues.toStation;
		cardElement.style.display = 'block';
		const { payload, status } = await fetchTrainData(trainValues.trainNumber);
		// console.log(payload);
		if (status === 200) {
			let trainData = payload.data;
			let attributes = trainData.attributes;
			let schedule = payload.included.find((item) => item.type === 'schedule').attributes;
			let route = payload.included.find((item) => item.type === 'route').attributes;
			let powerunit = payload.included.find((item) => item.type === 'powerunit').attributes;
			fromTimeElement.innerText = schedule.startTime;
			toTimeElement.innerText = schedule.endTime;
			trainTypeElement.innerText = attributes.trainType;
			trainDurationElement.innerText = convertMinsToHrsMins(schedule.duration);
			trainDistanceElement.innerText = `${route.distance} km`;
			trainPowerunitElement.innerText = `${powerunit.class} ${powerunit.number}`;
			trainSpeedElement.innerText = `${convertMpsToKmph(attributes.telemetryLatest.speed)} km/h`;
			trainLocationElement.innerText = `${attributes.telemetryLatest.latitude}, ${attributes.telemetryLatest.longitude}`;
			trainElevationElement.innerText = isNull(attributes.telemetryLatest?.elevation) ? '-' : `${attributes.telemetryLatest?.elevation} m`;
			trainLastUpdateElement.innerText = `${convertSeconds((new Date() - new Date(attributes.telemetryLatest.lastUpdated)) / 1000)} ago`;
		}
    } else {
		cardElement.style.display = 'none';
	}
});

let popover;
selectPointerMove.on('select', (e) => {
	if (e.selected.length !== 0 && e.selected[0]?.getProperties().geometry instanceof Point) {
		let trainNumber = e.selected[0]?.getProperties().geometry?.values_.trainNumber;
		let trainName = e.selected[0]?.getProperties().geometry?.values_.trainName;
		// let lat = e.selected[0]?.getProperties().geometry.values_.lat;
		// let long = e.selected[0]?.getProperties().geometry.values_.long;
		let fromStation = e.selected[0]?.getProperties().geometry?.values_.fromStation;
		let toStation = e.selected[0]?.getProperties().geometry?.values_.toStation;
		let lastUpdated = e.selected[0]?.getProperties().geometry?.values_.lastUpdated;
		let timeSinceUpdate = convertSeconds(Math.floor((new Date() - new Date(lastUpdated)) / 1000));
		let coordinates = e.selected[0]?.getProperties().geometry.getCoordinates();

		popup.setPosition(coordinates);
		popover = Popover.getInstance(popupElement);
		if (popover) {
			popover.dispose();
		}
		popover = new Popover(popupElement, {
			animation: true,
			container: popupElement,
			content: `<p>${fromStation} <span class="arrow">&rarr;</span> ${toStation}</p>
                      <p class="lastupdate">Last Updated: ${timeSinceUpdate} ago</p>`,
			html: true,
			placement: 'top',
			title: `${trainNumber} ${trainName}`,
		});
		popover.show();
		// console.log(e.selected[0]?.getProperties().geometry?.values_);
	} else {
		if (popover) {
			popover.hide();
		}
	}
});


// point.setCoordinates(fromLonLat([79.992674+i, 7.092157+i]));
setInterval(async () => {
	const { payload, status } = await fetchData();
	if (status === 200) {
		payload.data.forEach((train) => {
			displayTrain(train);
			updateTrain(train);
		});
	}

	if (selectClick.getFeatures().getLength() !== 0 && selectClick.getFeatures().getArray()[0].getProperties().geometry instanceof Point) {
		const values = selectClick.getFeatures().getArray()[0].getProperties().geometry.values_;
		const { payload, status } = await fetchTrainData(values.trainNumber);

		if (status === 200) {
			let telemetry = payload.data.attributes.telemetryLatest;
			console.log(telemetry);
			trainSpeedElement.innerText = `${convertMpsToKmph(telemetry.speed)} km/h`;
			trainLocationElement.innerText = `${telemetry.latitude}, ${telemetry.longitude}`;
			trainElevationElement.innerText = isNull(telemetry.elevation) ? '-' : `${telemetry.elevation} m`;
			trainLastUpdateElement.innerText = `${convertSeconds((new Date() - new Date(telemetry.lastUpdated)) / 1000)} ago`;
		}
	}
}, 10000);

function fetchAndLoadData() {
	fetchData().then(({payload, status}) => {
		if (status === 200) {
			payload.data.forEach((train) => {
				displayTrain(train);
				updateTrain(train);
			});
		}
	});
}

async function displayTrain(train) {
	// if there is a new train which is enRoute
	// add it to the feature array
	const attributes = train.attributes;
	const schedule = train.relationships.schedule.data.attributes;
	if (points[train.id] === undefined) {
		if (attributes.telemetryLatest.longitude === null || attributes.telemetryLatest.latitude === null) {
			return;
		}
		const point = new Point(fromLonLat([attributes.telemetryLatest.longitude, attributes.telemetryLatest.latitude]));
		point.setProperties({
			trainNumber: attributes.trainNumber,
			trainName: attributes.trainName,
			enRoute: attributes.enRoute,
			fromStation: schedule.fromStation,
			toStation: schedule.toStation,
		});
		points[train.id] = point;
		pointVectors.addFeature(new Feature(point));
	}
}

async function updateTrain(train) {
	const trainId = train.id;
	// console.log(points);
	const point = points[trainId];
	if (point === undefined) {
		return;
	}
	const long = train.attributes.telemetryLatest.longitude;
	const lat = train.attributes.telemetryLatest.latitude;
	if (long === null || lat === null) {
		return;
	}
	point.setProperties({
		long: long,
		lat: lat,
		lastUpdated: train.attributes.telemetryLatest.lastUpdated,
	});

	point.setCoordinates(fromLonLat([long, lat]));
}

function convertSeconds(seconds) {
	const days = Math.floor(seconds / (24 * 3600));
	seconds %= (24 * 3600);
	const hours = Math.floor(seconds / 3600);
	seconds %= 3600;
	const minutes = Math.floor(seconds / 60);
	seconds %= 60;

	if (days > 0) {
		return `${days} ${days > 1 ? 'days' : 'day'}`;
	} else if (hours > 0) {
		return `${hours} ${hours > 1 ? 'hours' : 'hour'}`;
	} else if (minutes > 0) {
		return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`;
	} else {
		return `${seconds} ${seconds > 1 ? 'seconds' : 'second'}`;
	}
}

function convertMinsToHrsMins(mins) {
	const hours = Math.floor(mins / 60);
	const minutes = mins % 60;
	return `${hours} h ${minutes} min`;
}

function convertMpsToKmph(mps) {
	let meterspersecond = mps;
	let kmperhour = meterspersecond * 3.6;
	return kmperhour.toFixed(2);
}